import {combineReducers} from "redux";

//Reducers
import categoriesReducer from "./categories";
import { categoriesReducerSp } from "./categories";
import { recentReviewsReducer } from "./reviews";
import categoryReducer from "./category";
import searchCatAndComp from "./searchCatAndComp";
import companieAboutReducer from "./companie-about";
import companiesSearchReducer from "./companies";
import companiesGoogleDetailsReducer from "./companie_details";
import { reviewsReducer, timeReducer, statusReducer, cityReducer} from "./filters";
import alertReducer from "./alert";
import searchCatDrop from "./searchCatDrop";
import fetchFindKeywords from "./fetchFindKeywords";
import { showSidebarReducer, showNavbarReducer } from "./Admin/sidebar";
import { getSubmission, FormActionBuilder, getThankYouMessage, getSurveys, formModal, fieldTypeReducer, FormFieldAdded, getForms, getFormDetails, getFormDetailsFields, FormClient} from "./Form/form";
import { getLocationTags } from "./Admin/tags";
import { getUserInfo, locationSelected, groupSelected, groupLocationSelected, impersonateReducer, demoReducer, cancelReducer, getInvoices, getAgreements, getLanguage} from "./Admin/user";
import { getLocationSources, getLocationAvaliableSources } from "./Admin/sources";
import { getReviews, getReviewsBoxes, getReviewsCoupon, getReview,  getReviewsHistory, getReviewNotes, getQuickReplies, getQuickReply, getFormattedReviews } from "./Admin/reviews";
import { reviewKeyword, reviewSourceFilter, paginationReviews, reviewSortOrder, memorizeLastReviewReducer, reviewStatusFilter, reviewTagsFilter, reviewRatingFilter, reviewRangeFilter, clearFiltersStatus, allLocationSources } from "./Admin/filter_review";
import { getNotifications, getNotificationsCount,getNotificationsUnread,getUnreadNotificationPagination, getNotificationPagination } from "./Admin/notifications";
import { ShowYoutubeContent } from "./Admin/youtubeContent";
import { newLocations, locationsTa } from "./Admin/locations";
import { topNavSearch } from "./Admin/topNavSearch";
import { getDashboardStats, getDashboardStatsVisitsReducer, getDashboardChartsReducer, getReviewCollectionDashboard } from "./Admin/dashboard";
import { getReviewPage } from "./Admin/reviewPage";
import { getNotificationSettings } from "./Admin/notificationSettings";
import { getReputation, getLocationsInformation, getLocationInformation, getLocationListing, getBusinessCategories,getLocationList, getLocationConnections, getConnectionFacebook, getConnectionGoogle, getConnectionSource, getConnectionTripadvisor } from "./Admin/locations_admin";
import { getUsersManagement, getUserManagement} from "./Admin/user_management"
import { analyticsReducer, keywordDetailsReducer, keywordAddedReducer } from "./Admin/analytics";
import { competitorsReducer } from "./Admin/competitors";
import { widgetCodeShareReducer } from "./Admin/widget_code-share";
import { getGroupPage, getGroups, getGroup } from "./Admin/group_page";
import {subscriptionsReducer} from "./Admin/subscriptions"
import { notFound } from "./Admin/404";
import { autoPilot, autoPilotSettings } from "./Admin/autoPilot";
import { getVideo } from "./Admin/video";
import { getGamifyMembers } from './Admin/gamify';
import { PageVisits } from "./Admin/PageVisits";
import accreditationReducer from "./accreditation";
import { getWalletReducer, getWalletVisibility } from "./Admin/wallet";
import { getSubscriptionValues } from "./Admin/subscription";
import { getUserPermissions } from "./Admin/user_permissions";
import { marketingMaterialsOrders } from "./Admin/marketingOrders";
import { getMarketingMaterialsOptions, getPreUplodedImagesReducer, checkForImageReducer, getCardMarketingMaterialReducer, getBillingDefaultAdressReducer, getDiscoverImagesReducer, getShippingDefaultAdressReducer, getCartReducer, getShippingServicesReducer, getOrdersReducer, getOrderReducer } from "./Admin/marketingMaterialOptions";
import {exportedAlertReducer} from "./Admin/exportedAlert"
import { getDownloadsReducer } from "./Admin/Downloads";
import { predefinedResponses } from "./Admin/predefinedResponses";
import { getReviewGenerateCoupons } from "./Admin/generatedCoupons";

const AllReducers = combineReducers({
    notFound,
    fetchFindKeywords,
    categoriesReducerSp,
    categoriesReducer,
    recentReviewsReducer,
    categoryReducer,
    searchCatAndComp,
    searchCatDrop,
    companieAboutReducer,
    companiesSearchReducer,
    companiesGoogleDetailsReducer,
    alertReducer,
    exportedAlertReducer,
    autoPilot,
    autoPilotSettings,
    reviewsReducer,
    timeReducer,
    statusReducer,
    cityReducer,
    formModal,
    fieldTypeReducer,
    FormFieldAdded,
    getForms,
    getFormDetails,
    getFormDetailsFields,
    FormClient,
    getSurveys,
    getThankYouMessage,
    FormActionBuilder,
    getSubmission,
    showSidebarReducer,
    showNavbarReducer,
    getLocationTags,
    getUserInfo,
    locationSelected,
    groupSelected,
    groupLocationSelected,
    impersonateReducer,
    demoReducer,
    cancelReducer,
    getLocationSources,
    getLocationAvaliableSources,
    getReviews,
    getReviewsCoupon,
    getReviewGenerateCoupons,
    getReviewsBoxes,
    getReviewNotes,
    reviewKeyword,
    reviewSourceFilter,
    reviewSortOrder,
    reviewStatusFilter,
    memorizeLastReviewReducer,
    getFormattedReviews,
    reviewRatingFilter,
    reviewRangeFilter,
    paginationReviews,
    reviewTagsFilter,
    getReview,
    getReviewsHistory,
    getNotifications,
    getNotificationsUnread,
    getNotificationPagination,
    getUnreadNotificationPagination,
    getNotificationsCount,
    getQuickReplies,
    getQuickReply,
    PageVisits,
    allLocationSources,
    clearFiltersStatus,
    ShowYoutubeContent,
    newLocations,
    locationsTa,
    topNavSearch,
    getDashboardStats,
    getDashboardStatsVisitsReducer,
    getDashboardChartsReducer,
    getReviewCollectionDashboard,
    getReviewPage,
    getNotificationSettings,
    getReputation,
    getLocationsInformation,
    getLocationInformation,
    getLocationListing,
    getBusinessCategories,
    getLocationList,
    getUsersManagement,
    getUserManagement,
    analyticsReducer,
    keywordDetailsReducer,
    keywordAddedReducer,
    competitorsReducer,
    widgetCodeShareReducer,
    getInvoices,
    getAgreements,
    getLanguage,
    getLocationConnections,
    getConnectionGoogle,
    getConnectionFacebook,
    getConnectionSource,
    getGroupPage,
    getGroups,
    getGroup,
    subscriptionsReducer,
    getVideo,
    getGamifyMembers,
    accreditationReducer,
    getSubscriptionValues,
    getWalletReducer,
    getWalletVisibility,
    getUserPermissions,
    marketingMaterialsOrders,
    getMarketingMaterialsOptions,
    getPreUplodedImagesReducer,
    getDiscoverImagesReducer,
    getCartReducer,
    getShippingServicesReducer,
    getOrdersReducer,
    getOrderReducer,
    getShippingDefaultAdressReducer,
    getCardMarketingMaterialReducer,
    getBillingDefaultAdressReducer,
    checkForImageReducer,
    getDownloadsReducer,
    predefinedResponses
})
export default AllReducers
