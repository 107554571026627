import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal } from "@mui/material"
import { FaYoutube } from "react-icons/fa";
import { closeVideoModal } from "../../../../redux/actions/admin-index"

const YoutubeVideo = () => {
    const dispatch = useDispatch()

    let videoSrc = useSelector(state => state.ShowYoutubeContent.path)
    let open = useSelector(state => state.getVideo.show)

    const handleClose = () => {
        dispatch(closeVideoModal())
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-youtube-title"
            aria-describedby="modal-youtube-description"
        >
            <div className="iframe-holder">
                <div className="iframe_head__wrapper">
                    <div>
                        <img src="/admin-images/logos_youtube.svg" alt="icon" />
                    </div>
                    <button onClick={() => handleClose()}>
                        <img src="/admin-images/close-icon-youtube-modal.svg" alt="icon" />
                    </button>
                </div>
                <div class="fitVid-wrapper">
                    <div class="features-video video-solutions position-relative">
                        <div class="video-solutions position-relative fitVideo" style={{ paddingTop: '56.25%', borderRadius: '14px' }}>
                            <iframe
                                frameBorder={0}
                                src={videoSrc}
                                width="100%"
                                height="100%"
                                allow='autoplay; encrypted-media'
                                allowFullScreen
                                title='video'>
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default YoutubeVideo