import { useEffect, useState } from 'react'
import { VscTriangleRight } from 'react-icons/vsc';
import { openVideoModal } from '../../../redux/actions/admin-index';
import { useDispatch } from 'react-redux';

const VideoTutorialButton = ({ title }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleScrollContainer = () => {
            if (window.scrollY > 300) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScrollContainer);
        return () => {
            window.removeEventListener('scroll', handleScrollContainer);
        };
    }, []);

    return (
        <div className={`video_tutorial_section ${isScrolled ? 'scrolled-class' : ''}`}>
            <div
                className="video_tutorial__wrapper"
                onClick={() => dispatch(openVideoModal())}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <img
                    src={isHovered ? "/admin-images/logos_youtube-learn-more.svg" : "/admin-images/help-circle-learn-more.svg"}
                    alt='icon'
                />
                <div className="text_wrapper">
                    <h5>Learn More</h5>
                    <h6>{title}</h6>
                </div>
            </div>
        </div>
    );
}

export default VideoTutorialButton;

// <div className="video_tutorial__wrapper" onClick={() => dispatch(openVideoModal())}>
//     <VscTriangleRight />
//     <div className="text_wrapper">
//         <h5>Learn More</h5>
//         <h6>{title}</h6>
//     </div>
// </div>