const initialReviewsState = {
    reviews: undefined,
    loading: true,
    loading_more: false
}
export const getReviews = (state = initialReviewsState, action) => {
    switch(action.type){
        case 'GET_REVIEWS':
            const currentReviews = state.reviews || { data: [], links: {}, meta: {} };
            const newReviews = action.payload.data;
            const existingReviewIds = new Set(currentReviews.data.map(review => review.id));
            const filteredNewReviews = newReviews.filter(review => !existingReviewIds.has(review.id));
            
            return {
                ...state,
                reviews: {
                    ...currentReviews,
                    data: [...currentReviews.data, ...filteredNewReviews],
                    links: action.payload.links,
                    meta: action.payload.meta
                },
                loading: false,
                loading_more: false
            }
        case 'SET_REVIEWS':
            return {
                ...state,
                reviews: {
                    data: action.payload.data,
                    links: action.payload.links,
                    meta: action.payload.meta
                },
                loading: false,
                loading_more: false
            }
        case 'LOADING_MORE':
            return{
                ...state,
                loading: false,
                loading_more: true
            }
        case 'LOADING_TABLES':
            return{
                ...state,
                loading: true,
                loading_more: false
            }
        case 'CLEAR_LOADING_TABLES':
            return{
                ...state,
                loading: false,
                loading_more: false
            }
        case 'TOGGLE_PINNED_STATUS':
            const { id, pin } = action.payload;
        
            const updatedReviews = state.reviews?.data?.map(review => {
                if (review.id === id) {
                    return {
                        ...review,
                        is_pinned: pin
                    };
                }
                return review;
            });

            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    data: [...(updatedReviews ?? [])]
                }
            };
        case 'REPLIED_STATUS':
            const { rev_id } = action.payload;
            const updatedRepliedReviews = state.reviews?.data?.map(review => {
                if (review.id === rev_id) {
                    return {
                        ...review,
                        responded: true
                    };
                }
                return review;
            });

            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    data: [...(updatedRepliedReviews ?? [])]
                }
            };
        case 'CLEAR_REVIEWS':
            return{
                ...state,
                reviews: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const getReview = (state = [], action) => {
    switch(action.type){
        case 'GET_REVIEW':
            return{
                ...state,
                review: action.payload,
            }
        case 'CLEAR_REVIEW':
            return{
                ...state,
                review: undefined,
            }
        default:
            return{
                ...state
            }
    }
}

export const getReviewsBoxes = (state = [], action) => {
    switch(action.type){
        case 'GET_REVIEWS_TOP_BOXES':
            return{
                ...state,
                reviews_boxes: action.payload,
            }
            case 'CLEAR_TOP_BOXES':
                return{
                    ...state,
                    reviews_boxes: undefined
                }
        default:
            return{
                ...state
            }
    }
}

export const getReviewsHistory = (state = [], action) => {
    switch(action.type){
        case 'GET_REVIEWS_HISTORY':
            return{
                ...state,
                history: action.payload,
                loading: false
            }
        case 'LOADING_HISTORY':
            return{
                history: undefined,
                loading: true
            }
        default:
            return{
                ...state
            }
    }
}

export const getQuickReplies = (state = [], action) => {
    switch(action.type){
        case 'GET_QUICK_REPLY':
            return{
                ...state,
                replies: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}
export const getQuickReply = (state = [], action) => {
    switch(action.type){
        case 'GET_QUICK_REPLY_DETAILS':
            return{
                ...state,
                reply: action.payload,
            }
            case 'CLEAR_QUICK_REPLY_DETAILS':
                return{
                    ...state,
                    reply: undefined
                }
        default:
            return{
                ...state
            }
    }
}

export const getReviewsCoupon = (state = [], action) => {
    switch(action.type){
        case 'GET_REVIEWS_COUPON':
            return{
                ...state,
                coupons: action.payload,
            }
        case 'CLEAR_REVIEWS':
            return{
                ...state,
                coupons: undefined
            }
        default:
            return{
                ...state
            }
    }
}
export const getReviewNotes = (state = [], action) => {
    switch(action.type){
        case 'GET_REVIEW_NOTES':
            return{
                ...state,
                notes: action.payload,
            }
        case 'CLEAR_REVIEW_NOTES':
            return{
                ...state,
                notes: undefined
            }

        case 'ADD_NOTES':
            return{
                ...state,
                notes: {
                    ...state.notes,
                    notes: [...state.notes.notes, action.payload]
                }
            }
        default:
            return{
                ...state
            }
    }
}
export function getFormattedReviews(state = {}, action) {
    switch(action.type){
        case 'FORMATTED_REVIEWS':
            const convertedData = {
                pinned: [],
                byDate: []
            };
            if (action.payload) {
                action.payload.data.forEach(item => {
                    const date = item.published_at.split(' ')[0];
                    if (item.is_pinned) {
                        convertedData.pinned.push(item);
                    } else {
                        const existingEntry = convertedData.byDate.find(entry => entry.date === date);
                        if (existingEntry) {
                            existingEntry.items.push(item);
                        } else {
                            convertedData.byDate.push({
                                date: date,
                                items: [item]
                            });
                        }
                    }
                });
            }
            return {
                ...state,
                formatted_reviews: convertedData
            };
        case 'CLEAR_FORMATTED_REVIEWS':
            return{
                ...state,
                formatted_reviews: undefined
            }
        default:
            return state;
    }
}
