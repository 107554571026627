import React, { useState, useEffect } from 'react';
import { IoIosArrowUp } from "react-icons/io";

const ScrollToTopCircle = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => {
        if (window?.scrollY > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window?.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    return (
        <div>
            {visible && 
                <button className='scroll__to_top_btn' onClick={scrollToTop}>
                    <IoIosArrowUp />
                </button>
            }
        </div>
    );
}

export default ScrollToTopCircle;
