export const getReputation = (state = [] ,action) => {
    switch(action.type){
        case 'GET_REPUTATION':
            return{
                ...state,
                reputation: action.payload
            }
        case 'CLEAR_REPUTATION':
            return{
                ...state,
                reputation: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const getLocationsInformation = (state = [] ,action) => {
    switch(action.type){
        case 'GET_LOCATIONS_INFORMATION':
            return{
                ...state,
                location_informations: action.payload
            }
        case 'CLEAR_LOCATIONS_INFORMATION':
            return{
                ...state,
                location_informations: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const getLocationInformation = (state = [] ,action) => {
    switch(action.type){
        case 'GET_LOCATION_INFORMATION':
            return{
                ...state,
                location_informations: action.payload
            }
        case 'CLEAR_LOCATION_INFORMATION':
            return{
                ...state,
                location_informations: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const getLocationListing = (state = [], action) => {
    switch(action.type){
        case 'GET_LOCATION_LISTING':
            return{
                ...state,
                listing: action.payload
            }
        case 'CLEAR_LOCATION_LISTING':
            return{
                ...state,
                listing: undefined
            }
        default: 
            return{
                ...state
        }
    }
}

export const getBusinessCategories = (state = [] ,action) => {
    switch(action.type){
        case 'GET_BUSINESS_CATEGORIES':
            return{
                ...state,
                business_categories: action.payload
            }
        case 'CLEAR_BUSINESS_CATEGORIES':
            return{
                ...state,
                business_categories: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const getLocationList = (state = [], action) => {
    switch(action.type){
        case 'GET_LOCATION_LIST':
            return{
                ...state,
                loc_list: action.payload
            }
        case 'CLEAR_LOCATION_LIST':
            return{
                ...state,
                loc_list: undefined
            }
        default: 
            return{
                ...state
        }
    }
}

export const getLocationConnections = (state = [], action) => {
    switch(action.type){
        case 'GET_LOCATION_CONNECTIONS':
            return{
                ...state,
                connections: action.payload,
                loading: false
            }
        case 'LOADING_CONNECTIONS':
            return{
                ...state,
                loading: true
            }
        case 'CLEAR_LOCATION_CONNECTION':
            return{
                ...state,
                connections: undefined
            }
        default: 
            return{
                ...state
        }
    }
}


export const getConnectionFacebook = (state = [], action) => {
    switch(action.type){
        case 'GET_CONNECTIONS_FACEBOOK':
            return{
                ...state,
                connection_facebook: action.payload
            }
        case 'CLEAR_CONNECTION_FACEBOOK':
            return{
                ...state,
                connection_facebook: undefined
            }
        default: 
            return{
                ...state
        }
    }
}

export const getConnectionGoogle = (state = [], action) => {
    switch(action.type){
        case 'GET_CONNECTIONS_GOOGLE':
            return{
                ...state,
                connection_google: action.payload
            }
        case 'CLEAR_CONNECTION_GOOGLE':
            return{
                ...state,
                connection_google: undefined
            }
        default: 
            return{
                ...state
        }
    }
}

const initialState = {
    connection_source: null,
    
};
export const getConnectionSource = (state = initialState, action) => {
    switch(action.type) {
        case 'GET_CONNECTIONS_SOURCE':
            return {
                ...state,
                connection_source: action.payload,
            }
        case 'CLEAR_CONNECTION_SOURCE':
            return {
                ...state,
                connection_source: null,
            }
        default: 
            return state;
    }
}